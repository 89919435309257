

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.search-preview-header {
    color : #7d2222;
}

.search-preview-count-font {
    color: #000000;
}

.search-result-preview-count {
    color: #ff6a00;
    font-size: smaller;
    background-color: #000000;
    border-radius: 12px;
}

.search-result-preview-button {
    background-color: #000000;
    border: none;
    color: white;    
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: smaller;
}
.box {
    width: 1000px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    background: #0057e3;
}

.preview_panel {
    width: 80vw;
    height: 80vh;
}

.overlay {
    z-index: 9;
    margin: 30px;
    background: #009938;
}

.Preview-Content-Font {
    font-size: 12px;
}
.Preview-Header-Font {
    font-size: 10px;
}

.border-preview {
    border: 1px solid black;
    padding: 1px;
}

svg.p-icon.p-row-toggler-icon {
    background-image: url("../images/ppl.svg");
    background-size: cover;   
    width: 18px;
    height: 18px;
    margin-left: -10px;
    opacity: 1;
    display: block;
    fill-opacity: 1;  
}
button.p-row-toggler.p-link {      
    background-size: cover;    
    width: 22px;     
    fill-opacity: 1;
}
.alcs-chassis {
    min-height: calc(100vh - 156px) !important;
}

#application-content {
    height: calc(100vh - 156px);
}

#footer {
    width: 100vw;
    height: 20px;
}

.breadcrumb {    
    text-decoration: none;
}
.custom-top-header {
    height: 10px;
}

.custom-badge .p-badge {
    background-color: black; 
    color: white; 
}
