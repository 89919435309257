$alcsWhite: #ffffff !default;
$alcsBlack: #000000 !default;
$alcsViolet: #5950cf !default;
$alcsGreen: #00ce5a !default;
$alcsOrange: #ff4227 !default;
$alcsYellow: #fed200 !default;
$alcsPink: #ff9bd8 !default;
$alcsNavy: #00006e !default;
$alcsAqua: #50dddd !default;
$alcsSage: #005a08 !default;
$alcsRed: #ff165c !default;

$alcsGrey: #B6B8B9 !default;
$alcsDarkGrey: #525252 !default;
$alcsMediumGrey: #DEE2E6 !default;
$alcsLightGrey: #F7F7F7 !default;

$alcsSage75: #408446 !default;

$alcsGreen15: #D9F8E6 !default;

$alcsOrange15: #FFE3DF !default;


@import "./_variables";
@import "./_fonts";
@import '../../theme-base/_components';
@import "./_extensions";

// Component font size
/*.p-component {
    font-size: 14px;
}*/

//Dropdown
.p-dropdown:not(.p-disabled).p-focus {
    border-color: $alcsGreen;
}

.p-dropdown {
    align-items: center;
}

.p-dropdown.icon-left {
    display: inline-flex;
}

.p-dropdown.icon-left::before {
    display: flex;
    font-size: 18px;
    margin-left: 10px;
    color: $alcsDarkGrey;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.p-dropdown .p-inputtext {
    font-size: 14px;
    font-weight: bold;
    padding: 7.5px 15px 7.5px 10px;
    color: $alcsDarkGrey;
}

.p-dropdown .p-dropdown-clear-icon {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.p-dropdown .p-placeholder span,
.p-dropdown-label.p-inputtext span,
.p-dropdown-panel .p-dropdown-item span {
    display: flex;
    align-items: center;
}

.p-dropdown .p-placeholder i,
.p-dropdown-label.p-inputtext i {
    margin-right: 10px;
    font-size: 18px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item i {
    margin-right: 10px;
}

.p-dropdown .p-dropdown-trigger {
    width: auto;
    padding: 7.5px 15px 7.5px 0px;
    color: $alcsDarkGrey;
}

.p-dropdown.grey {
    background: $alcsDarkGrey;
    border: 1px solid $alcsDarkGrey;
}

.p-dropdown.green {
    background: $alcsGreen;
    border: 1px solid $alcsGreen;
}

.p-dropdown.yellow {
    background: $alcsYellow;
    border: 1px solid $alcsYellow;
}

.p-dropdown.red {
    background: $alcsRed;
    border: 1px solid $alcsRed;
}

.p-dropdown.green .p-dropdown-label.p-placeholder,
.p-dropdown.green .p-dropdown-trigger,
.p-dropdown.green .p-inputtext,
.p-dropdown.green .p-dropdown-clear-icon,
.p-dropdown.green.icon-left::before,
.p-dropdown.yellow .p-dropdown-label.p-placeholder,
.p-dropdown.yellow .p-dropdown-trigger,
.p-dropdown.yellow .p-inputtext,
.p-dropdown.yellow .p-dropdown-clear-icon,
.p-dropdown.yellow.icon-left::before {
    color: $alcsBlack;
}

.p-dropdown.grey .p-dropdown-label.p-placeholder,
.p-dropdown.grey .p-dropdown-trigger,
.p-dropdown.grey .p-inputtext,
.p-dropdown.grey .p-dropdown-clear-icon,
.p-dropdown.grey.icon-left::before {
    color: $alcsWhite;
}

.p-dropdown.grey.p-focus {
    box-shadow: 0 0 0 0.2rem $alcsMediumGrey;
}

.p-dropdown.green.p-focus {
    background: lighten($alcsGreen, 10%);
    box-shadow: 0 0 0 0.2rem $alcsGreen;
}

.p-dropdown.yellow.p-focus {
    background: lighten($alcsYellow, 10%);
    box-shadow: 0 0 0 0.2rem $alcsYellow;
}

.p-dropdown.red.p-focus {
    background: lighten($alcsRed, 10%);
    box-shadow: 0 0 0 0.2rem $alcsRed;
}

.p-dropdown-panel.p-component {
    font-size: 14px;
}

.p-dropdown-panel.grey {
    background: $alcsDarkGrey;
    color: $alcsWhite;
}

.p-dropdown-panel.green {
    background: $alcsGreen;
}

.p-dropdown-panel.yellow {
    background: $alcsYellow;
}

.p-dropdown-panel.red {
    background: $alcsRed;
}

.p-dropdown-panel.grey .p-dropdown-items .p-dropdown-item {
    color: $alcsBlack;
}

.p-dropdown-panel.grey .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel.grey .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $alcsGrey;
    color: $alcsBlack;
}

.p-dropdown-panel.green .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel.yellow .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel.red .p-dropdown-items .p-dropdown-item {
    color: $alcsBlack;
}

.p-dropdown-panel.green .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel.green .p-dropdown-items .p-dropdown-item.p-highlight {
    background: lighten($alcsGreen, 10%);
    color: $alcsBlack;
}

.p-dropdown-panel.yellow .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel.yellow .p-dropdown-items .p-dropdown-item.p-highlight {
    background: lighten($alcsYellow, 10%);
    color: $alcsBlack;
}

.p-dropdown-panel.red .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel.red .p-dropdown-items .p-dropdown-item.p-highlight {
    background: lighten($alcsRed, 10%);
    color: $alcsBlack;
}

.p-dropdown-panel.grey .p-dropdown-items .p-dropdown-item {
    color: $alcsWhite;
}

// Buttons
.p-button.p-component {
    font-size: 14px;
}

.p-row-toggler,
.p-button {
    padding: 8px 15px 8px 15px;
    background: $alcsDarkGrey;
    border: 1px solid $alcsDarkGrey;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    background: $alcsDarkGrey;
    color: $alcsWhite
}

.p-row-editor-init-icon {
    max-height: 15px;
}

.p-button.p-togglebutton.p-highlight {
    background: $alcsSage;
    border-color: $alcsSage;
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button:not(:disabled):hover,
.p-button:not(:disabled):focus,
.p-row-toggler:not(:disabled):hover,
.p-row-toggler:not(:disabled):focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:not(:disabled):hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:not(:disabled):focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:not(:disabled):hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:not(:disabled):focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:not(:disabled):hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:not(:disabled):focus {
    background: $alcsSage;
    border: 1px solid $alcsSage;
}

.p-row-toggler {
    color: $alcsWhite;
}

.p-row-toggler .p-row-toggler-icon.pi {
    font-size: 14px;
}

.p-button-label {
    font-weight: 700;
}

.p-button.orange {
    background: $alcsOrange;
    border: 1px solid $alcsOrange;
    color: $alcsBlack;
}

.p-splitbutton.green,
.p-splitbutton.green .p-button,
.p-button.green {
    background: $alcsGreen;
    border: 1px solid $alcsGreen;
    color: $alcsBlack;
}

.p-splitbutton.yellow,
.p-splitbutton.yellow .p-button,
.p-button.yellow {
    background: $alcsYellow;
    border: 1px solid $alcsYellow;
    color: $alcsBlack;
}

.p-splitbutton.red,
.p-splitbutton.red .p-button,
.p-button.red {
    background: $alcsRed;
    border: 1px solid $alcsRed;
    color: $alcsBlack;
}

.p-button.white {
    background: $alcsWhite;
    border: 1px solid $alcsWhite;
    color: $alcsBlack;
}

.p-button.orange:not(:disabled):hover,
.p-button.orange:not(:disabled):focus {
    background: lighten($alcsOrange, 10%);
    border: 1px solid lighten($alcsOrange, 10%);
}

.p-button.green:not(:disabled):hover,
.p-button.green:not(:disabled):focus,
.p-splitbutton.green .p-button:not(:disabled):hover,
.p-splitbutton.green .p-button:not(:disabled):focus {
    background: lighten($alcsGreen, 10%);
    border: 1px solid lighten($alcsGreen, 10%);
}

.p-button.yellow:not(:disabled):hover,
.p-button.yellow:not(:disabled):focus,
.p-splitbutton.yellow .p-button:not(:disabled):hover,
.p-splitbutton.yellow .p-button:not(:disabled):focus {
    background: lighten($alcsYellow, 10%);
    border: 1px solid lighten($alcsYellow, 10%);
}

.p-button.red:not(:disabled):hover,
.p-button.red:not(:disabled):focus,
.p-splitbutton.red .p-button:not(:disabled):hover,
.p-splitbutton.red .p-button:not(:disabled):focus {
    background: lighten($alcsRed, 10%);
    border: 1px solid lighten($alcsRed, 10%);
}

.p-button.white:not(:disabled):hover,
.p-button.white:not(:disabled):focus {
    background: $alcsGreen15;
    border: 1px solid $alcsGreen15;
}

// Radio Button

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    border-color: $alcsGreen;
}

.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
    box-shadow: none;
}

// SplitButton - Icon Only

.p-splitbutton.p-component {
    border: 0px;
}

.p-splitbutton.modal-only .p-splitbutton-defaultbutton {
    display: none;
}

.p-splitbutton.modal-only .p-splitbutton-menubutton {
    border-bottom-left-radius: 0.375rem;
    ;
    border-top-left-radius: 0.375rem;
    ;
}

.p-splitbutton.p-button-rounded.modal-only>.p-button {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}


// Button Focus
@mixin alcs-focused-ring($ring-color) {
    box-shadow: 0 0 0 0px #ffffff, 0 0 0 4px $ring-color, 0 1px 2px 0 rgba(0, 0, 0, 0.0);
}

.p-button,
.p-row-toggler {
    &:focus {
        @include alcs-focused-ring($alcsGreen);
    }
}

.p-button.orange {
    &:focus {
        @include alcs-focused-ring($alcsOrange);
    }
}

.p-button.green {
    &:focus {
        @include alcs-focused-ring($alcsGreen);
    }
}

.p-button.yellow {
    &:focus {
        @include alcs-focused-ring($alcsYellow);
    }
}

.p-button.red {
    &:focus {
        @include alcs-focused-ring($alcsRed);
    }
}

#header-search .p-button {
    &:focus {
        @include alcs-focused-ring(transparent);
    }
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: auto;
    padding: 8px;
}

.p-button.p-button-icon-only,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
    width: 35px;
    min-width: 35px;
    height: 35px;
}

.p-button.p-button-danger.p-button-text {
    color: $alcsWhite;
}

// Header Search

#header-search.p-inputgroup {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#header-search .p-inputtext.p-component,
#header-search .p-inputtext.p-component::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 500;
}

#header-search .p-inputtext.p-component::-webkit-input-placeholder {
    color: $alcsDarkGrey;
}

#header-search .p-inputtext {
    padding: 15px 15px 15px 20px;
    border: 0px solid transparent;
}

#header-search.p-inputgroup input:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

#header-search .p-button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 8px 20px 8px 20px;
    background: transparent;
    border: 1px solid transparent;
    color: $alcsDarkGrey;
    background: $alcsWhite;
}

#header-search .p-button.p-button-icon-only {
    width: auto;
}

#header-search .p-button .p-button-icon.pi {
    font-size: 20px;
}

#header-search .p-inputtext:enabled:focus,
#header-search .p-inputtext.p-filled,
#header-search .p-inputtext:enabled:focus~.p-button,
#header-search .p-inputtext.p-filled~.p-button {
    box-shadow: none;
    border-color: transparent;
    background: $alcsYellow;
    color: $alcsBlack;
}

#header-search .p-button.p-button-icon-only {
    height: auto;
    justify-content: center;
    align-items: center;
}

//Inline Search
.inline-search {
    max-width: 215px;
}

.inline-search .p-inputtext {
    padding: 6px;
    border-right: 0px;
}

.inline-search .p-button {
    border-top: 1px solid #d4d4d8;
    border-right: 1px solid #d4d4d8;
    border-bottom: 1px solid #d4d4d8;
}

// TabView
.p-tabview.p-component {
    font-size: 14px;
    position: relative;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $alcsSage;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview.auto-width .p-tabview-nav-container {
    display: flex;
}

.p-tabview.custom-content .p-tabview-nav {
    border-bottom-color: transparent;
}

/*ul.p-tabview-nav {
    list-style: none;
    margin-block-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
    display: flex;
    flex-direction: row;
    position: relative;
}*/

/*.p-tabview .p-tabview-nav li {
    display: flex;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    bottom: -2px;
}*/

//Breadcrumb
.p-breadcrumb.p-component {
    font-size: 14px;
}

.p-breadcrumb-list>li.p-menuitem {
    font-weight: 600;
}

.p-breadcrumb-list li.p-menuitem:hover .p-menuitem-link .p-menuitem-text {
    color: $alcsSage;
}

//DataTable
.p-datatable.p-component {
    font-size: 14px;
}

.p-datatable-table {
    border-color: $alcsMediumGrey;
    border: 1px solid $alcsMediumGrey;
    border-radius: 6px;
}

.p-datatable-table thead {
    font-size: 12px;
}

.p-datatable-table thead .p-icon {
    width: 15px;
    height: 15px;
}

.p-datatable-table>thead>tr>th:first-of-type {
    border-top-left-radius: 5px;
}

.p-datatable-table>thead>tr>th:last-of-type {
    border-top-right-radius: 5px;
    border-right: 0px;
}

.p-datatable-table>tbody>tr:last-of-type td {
    border-bottom: 0px;
}

.p-datatable-table>tbody>tr:last-of-type>td:first-of-type {
    border-bottom-left-radius: 5px;
}

.p-datatable-table>tbody>tr:last-of-type>td:last-of-type {
    border-bottom-right-radius: 5px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: $alcsGreen15;
    color: $alcsBlack;
}

.p-datatable-row-expansion {
    background: $alcsMediumGrey;
}

//Checkbox
.p-checkbox .p-checkbox-box {
    border-radius: 8px;
    padding: 2px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px $alcsGreen, 0 1px 2px 0 rgba(0, 0, 0, 0);
    border-color: $alcsGreen;
}

//Table Filter
.p-datatable .p-sortable-column .p-column-title {
    margin-right: auto;
}

.p-column-filter-menu {
    margin-right: 0;
    margin-left: 5px;
}

.p-column-filter-menu-button {
    color: $alcsWhite;
    width: 27px;
    height: 27px;
}

.p-column-filter-menu-button:hover {
    background: $alcsSage75;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $alcsSage;
}

// Pagination
.p-paginator {
    background: #ffffff;
    color: $alcsDarkGrey;
    border: solid #f3f4f6;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: $alcsDarkGrey;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: $alcsGreen15;
    border-color: transparent;
    color: $alcsDarkGrey;
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 3rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
}

.p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: $alcsWhite;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: $alcsDarkGrey;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $alcsSage;
    border-color: #ecfeff;
    color: $alcsWhite;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: $alcsGreen15;
    border-color: transparent;
    color: $alcsDarkGrey;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 35px;
    height: 35px;
}

.p-paginator-element.p-link {
    font-size: 14px;
}

//Badge
.p-badge.p-badge-xl {
    padding: 0 15px;
    border-radius: 30px;
    font-weight: 600;
}

.p-badge.p-badge-lg {
    padding: 0 10px;
    border-radius: 20px;
}

//Tags
.grey.p-tag.p-component {
    background: $alcsDarkGrey;
}

//Dialog
.search-results-dialog.p-dialog {
    width: calc(100vw - 60px);
    min-height: calc(100vh - 60px);
}

.search-results-dialog.p-dialog .p-dialog-header {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.search-results-dialog.p-dialog .p-dialog-content:last-of-type {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.search-results-dialog.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 16px;
}

.p-component-overlay-enter {
    animation: new-p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: new-p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
    @keyframes new-p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }

        to {
            background-color: rgba(0, 0, 0, 0.40);
        }
    }

    @keyframes new-p-component-overlay-leave-animation {
        from {
            background-color: vrgba(0, 0, 0, 0.40);
        }

        to {
            background-color: transparent;
        }
    }
}

// Input
.p-inputtext:enabled:focus {
    border-color: $alcsGreen;
}

// Multi Select
.p-multiselect.expanding.p-component {
    font-size: 14px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: $alcsDarkGrey;
}

.p-multiselect.expanding.p-inputwrapper {
    max-width: 100%;
    height: auto;
    background-color: $alcsLightGrey;
}

.p-multiselect.expanding .p-multiselect-label-container {
    flex-wrap: wrap;
    display: flex;
    height: auto;
    overflow-y: auto;
}

.p-multiselect.expanding .p-multiselect-label-container .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
}

.p-multiselect.expanding .p-multiselect-trigger {
    align-items: flex-start;
}

.p-multiselect.expanding .p-multiselect-trigger svg {
    margin-top: 15px;
}

.p-multiselect.expanding .p-multiselect-token {
    background-color: $alcsSage;
    border-radius: 0.375rem;
    font-size: 12px;
    color: $alcsWhite;
    font-weight: 700;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}

.p-inputwrapper-filled.p-multiselect.expanding.p-multiselect-chip .p-multiselect-label {
    padding: 0.375rem 0.375rem;
}

// Chips
.p-chips .p-chips-multiple-container {
    padding: 0.375rem 0.375rem;
}

.p-chips .p-inputtext {
    background-color: $alcsLightGrey;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0;
}

.p-chips .p-chips-multiple-container {
    gap: 0;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    background-color: $alcsSage;
    border-radius: 0.375rem;
    font-size: 12px;
    color: $alcsWhite;
    font-weight: 700;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}