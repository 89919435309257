
/**** ALCS Colours ****/
:root {
    --alcsWhite: #ffffff;
    --alcsBlack: #000000;
    --alcsViolet: #5950cf;
    --alcsGreen: #00ce5a;
    --alcsOrange: #ff4227;
    --alcsYellow: #fed200;
    --alcsPink: #ff9bd8;
    --alcsNavy: #00006e;
    --alcsAqua: #50dddd;
    --alcsSage: #005a08;
    --alcsRed: #ff165c;
    --alcsGrey: #B6B8B9;
    --alcsDarkGrey: #525252;
    --alcsMediumGrey: #DEE2E6;
    --alcsLightGrey: #F7F7F7;
    --alcsSage75: #408446;
    --alcsGreen15: #D9F8E6;
}


.alcs-chassis {   
    min-width: 1920px;
    min-height: calc(100vh - 156px);    
    display: flex;
    flex-direction: column;
    background-color: #B6B8B9;
    position: relative;
}

    .alcs-chassis .top-header {
        background-color: #000000;
        min-height: 80px;
        width: 100%;
        z-index: 1051;
    }

        /******* Brand Margin *******/

        .alcs-chassis .top-header .chassis-brand {
            margin-right: 30px;
            margin-left: 20px;
            margin-top: 15px;
        }

.p-button .p-badge {
    background-color: white !important; /* New background color with !important */
    color: black !important; /* New font color with !important */
}

.p-datatable .p-datatable-header, .p-datatable .p-datatable-thead {
    border: none; /* Remove borders from the header cells */
}
/*Customize Button*/
.button-left-align {
    text-align: left !important;
}

/* Customize the Dropdown component */
.p-dropdown.black {
    background: #000000;
    border: 1px solid #000000;
}

    .p-dropdown.black .p-dropdown-label.p-placeholder,
    .p-dropdown.black .p-dropdown-trigger,
    .p-dropdown.black .p-inputtext,
    .p-dropdown.black .p-dropdown-clear-icon,
    .p-dropdown.black.icon-left::before {
        color: #ffffff;
    }

    .p-dropdown.black.p-focus {
        box-shadow: 0 0 0 0.2rem #525252;
    }

.p-dropdown-panel.black {
    background: #000000;
    color: #ffffff;
}

    .p-dropdown-panel.black .p-dropdown-items .p-dropdown-item {
        color: #ffffff;
    }

        .p-dropdown-panel.black .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
        .p-dropdown-panel.black .p-dropdown-items .p-dropdown-item.p-highlight {
            background: #353935 !important;
            color: #ffffff !important;
        }

        .p-dropdown-panel.black .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
            color: #000000;
            background-color: #ffffff;
        }

.p-dropdown-panel.grey .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: black !important;
}

.p-dropdown .p-inputtext {
    padding: 11.5px 12px 11.5px 10px !important;
}
#noResultsScrollPanel .p-scrollpanel-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

#noResultsDiv {
    text-align: center;
}

#noResultsIcon {
    font-size: 20px;
    margin-bottom: 8px;
}

#noResultsMessage1 {
    margin-bottom: 0;
    font-size: 10px;
}

#noResultsMessage2 {
    margin-top: 0;
    font-size: 10px;
}

#noResultsSearchTerm {
    font-size: 14px;
}

.top-cta-left {
    margin-top: 22px;
}

.search-container {
    margin-top: 18px;
    margin-bottom: 18px;
}

.top-cta-right {
    margin-top: 22px;
}

.burger-menu-container {
    margin-top: 15px;
}
.custom-tab-panel .p-tabview-panels {
     padding: 0 !important;
}
.bold-titles {
    font-size: 16px;
    font-weight: 700;
}

.episode-bold-titles {
    font-size: 20px;
    font-weight: 600;
}

.codes-style {
    font-size: 12px;
    background-color: var(--alcsSage);
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}
.episode-history-label {
    display: inline-flex;
    color: var(--alcsGrey);
    font-size: 12px;
    font-weight: 700;
    margin: 0px 6px 3px 6px
}

.episode-history-data {
    display: inline-flex;
    color: black;
    font-size: 12px;
    font-weight: 700;
    margin: 0px 6px 3px 6px 
}

.alcs-chassis .application-content {
    padding: 30px 15px;
    height: calc(100vh - 156px) !important 
}

    .alcs-chassis .application-content .app-sidebar,
    .app-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }


        .alcs-chassis .application-content .app-sidebar .details-header {
            background-color: #000000;          
            border-top: 8px solid var(--alcsSage);
            width: 100%;
        }

            .alcs-chassis .application-content .app-sidebar .details-header .header {
                text-align: right;
            }

            .alcs-chassis .application-content .app-sidebar .details-header .title, .alcs-chassis .application-content .app-sidebar .search-header .title {
                display: inline-flex;
                background-color: var(--alcsSage);
                color: white;
                font-size: 14px;
                font-weight: 700;
                padding: 6px 15px 10px 15px;
                line-height: 1;
            }

        .alcs-chassis .application-content .app-sidebar .search-header {
            background-color: #000000;
            height: 10%;
            border-top: 8px solid var(--alcsSage);
            width: 100%;
        }

            .alcs-chassis .application-content .app-sidebar .search-header .header {
                text-align: left;
            }

        .alcs-chassis .application-content .app-sidebar .programme-title {
            color: white;
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            padding: 6px 25px 35px 25px;
        }

        .alcs-chassis .application-content .app-sidebar .other-titles-header {
            color: white;
            text-align: left;
            margin: 10px;
            padding: 20px 10px 20px 10px;
            border: 2px var(--alcsWhite);
        }

        .alcs-chassis .application-content .app-sidebar .other-titles-content {
            margin: 10px;
            text-align: left;
            color: white;
            padding: 5px 10px 20px 10px;
            font-weight: 700;
            font-size: 14px;
            height: 60%
        }

        .alcs-chassis .application-content .app-sidebar .details-content {
            background-color: var(--alcsWhite);
            height: 100%;
            width: 100%;
        }
      
            .alcs-chassis .application-content .app-sidebar .details-content fieldset, .sub-section fieldset {
                border: none;
                position: relative;
                color: var(--alcsBlack);
                width: 95%;
            }

            .alcs-chassis .application-content .app-sidebar .details-content .p-scrollpanel {
                width: 100%;
                height: 475px;
               
            }

            .alcs-chassis .application-content .app-sidebar .details-content legend,
            .sub-section legend {
                position: absolute;
                top: -10px;
                left: 0;
                background-color: var(--alcsWhite);
                padding-left: 30px;
                font-weight: 700;
                font-size: 14px;
            }

            .sub-section legend {
                padding-left: 0px;
            }

                .alcs-chassis .application-content .app-sidebar .details-content fieldset::before, .sub-section fieldset::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    border-top: 2px solid var(--alcsGrey);
                }

.alcs-chassis .application-content .app-sidebar .app-footer {
    background-color: var(--alcsLightGrey);
    padding: 10px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;   
    flex-shrink: 0;   
    position: sticky;
    bottom: 0;
    z-index: 10;
}
.sub-panel fieldset {
    border: none;
    position: relative;
    color: var(--alcsBlack);
    width: 95%;
}

.sub-panel legend {
    position: absolute;
    top: -10px;
    left: 0;
    background-color: var(--alcsWhite);
    padding: 0 0px;
    font-weight: 700;
    font-size: 14px;
}
.sub-panel fieldset::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid var(--alcsGrey);
}
.app-footer {
    background-color: var(--alcsLightGrey);
    padding: 10px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;    
    flex-shrink: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
#edit-titles-dialog .p-dialog-footer {
    padding: 1px 0px 0px 0px !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

fieldset {
    border: none;
    position: relative;
    color: white;
    width: 90%;
}

legend {
    position: absolute;
    top: -10px;
    left: 0;
    background-color: var(--alcsBlack);
    padding: 0 25px;
    font-weight: 700;
    font-size: 14px;
}

fieldset::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid white;
}

.label-input {
    font-size: 14px;
    font-weight: 700;
}

.av-radio-option {
    font-size: 14px;
    font-weight: 600;
}

.custom-full-width {
    width : 100%;
}
.av-secondarycountry {
    width: 100%;
    height : 100px;
}

.av-production-company {
    width: 100%;
    height: 150px;
}
.changed-field {
    font-style: italic;
}
.episode-panel {
    flex: 1; /* Allow the main content to grow and fill available space */
    overflow-y: auto; /* Add scrolling to the content if needed */
}
.episode-panel-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure the wrapper takes full height */
}

.episode-details-footer {
    flex-shrink: 0; /* Prevent the footer from shrinking */
    margin-top: auto; /* Push the footer to the bottom */
    position: sticky;
    bottom: 0;
    z-index:10;
}

.episode-panel .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.episode-panel .content {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
}
.toast-right {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 500px; 
    z-index: 1000; /* Ensure it appears above other elements */
}


#details-search.p-inputgroup {
    border: 1px solid var(--alcsMediumGrey);    
}
#details-search .p-inputtext {
    padding: 15px 15px 15px 20px;
    border: 0px solid transparent;
}
#details-search.p-inputgroup input:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

#details-search .p-button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 8px 20px 8px 20px;
    background: transparent;
    border: 1px solid transparent;
    color: var(--alcsGrey);
    background: var(--alcsWhite);
}

    #details-search .p-button.p-button-icon-only {
        width: auto;
    }

    #details-search .p-button .p-button-icon.pi {
        font-size: 20px;
    }

.p-field {
    margin-bottom: 1rem;
}

.p-formgroup-inline {
    display: flex;
    align-items: center;
}

.p-field-radiobutton {
    margin-right: 1rem;
}
/******* Autocomplete ****************/
.autocomplete-input-group {
    position: relative;
}

.autocomplete-search-button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    background-color: var(--alcsSage);
    border-radius: 0.375rem;
    font-size: 12px;
    color: var(--alcsWhite);
    font-weight: 700;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}

.sub-panel {
    padding: 20px;
}

.main-title, .other-titles, .panel-actions {
    margin-bottom: 20px;
}

.title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
/* Start mods PH */

.alcs-chassis .application-content .app-content .details-dashboard {
    background-color: #000000;
    min-height: 132px;
    border-top: 8px solid var(--alcsSage);
    width: 100%;
}

.alcs-chassis .application-content .app-content .details-dashboard-cpt {
    float: left;
}

    .alcs-chassis .application-content .app-content .details-dashboard-cpt .title {
        display: inline-flex;
        background-color: var(--alcsSage);
        color: white;
        font-size: 14px;
        font-weight: 700;
        padding: 6px 15px 10px 15px;
        margin-left: 6px;
        line-height: 1;
    }

.alcs-chassis .application-content .app-content .details-dashboard .details-dashboard-label {
    display: block;
    color: var(--alcsGrey);
    font-size: 12px;
    font-weight: 700;
    margin: 0px 6px 3px 6px;
}

.alcs-chassis .application-content .app-content .details-dashboard .details-dashboard-data {
    display: block;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-left: 10px;
}
.alcs-chassis .application-content .app-content .details-dashboard .details-dashboard-label-in {
    display: inline-flex;
    color: var(--alcsGrey);
    font-size: 12px;
    font-weight: 700;
    margin: 0px 6px 3px 6px;
}

.alcs-chassis .application-content .app-content .details-dashboard .details-dashboard-data-in {
    display: inline-flex;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-left: 10px;
}


.alcs-chassis .application-content .app-content .details-dashboard .details-dashboard-data-lg {
    display: inline-flex;
    background-color: var(--alcsSage);
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    line-height: 1;
}


.alcs-chassis .application-content .app-content .details-dashboard ul {
    padding-inline-start: 0;
    margin-top: 6px;
    margin-block-end: 0px;
}





/* End mods PH */

/***Edit Titles*/
.dotted-separator {
    border-bottom: 1px dotted #000;
    padding-Bottom: 10px;
}

/***Edit Titles Ends*/
/******* Secondary Navigation *******/
.alcs-chassis .secondary-navigation {
    background-color: white;
}

    .alcs-chassis .secondary-navigation #type-navigation {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

#type-navigation .type-nav-btn {
    display: flex;
    height: 100%;
    color: #000000;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    position: relative;
    z-index: 1;
    transition: color ease 200ms;
    text-decoration: none;
}

    #type-navigation .type-nav-btn:hover {
        color: white;
        cursor: pointer;
        transition: color ease 200ms;
    }

    #type-navigation .type-nav-btn::before {
        content: '';
        display: flex;
        width: 100%;
        height: 8px;
        position: absolute;
        z-index: -1;
        background-color: #000000;
        bottom: 0;
        left: 0;
        transition: height ease 500ms;
    }

    #type-navigation .type-nav-btn:hover::before,
    #type-navigation .type-nav-btn.active::before {
        height: 100%;
        transition: height ease 500ms;
    }

    #type-navigation .type-nav-btn#av-radio::before {
        background-color: #FF165C;
    }

    #type-navigation .type-nav-btn#serials::before {
        background-color: #5950CF;
    }

    #type-navigation .type-nav-btn#visual-arts::before {
        background-color: #FF4227;
    }

    #type-navigation .type-nav-btn#books::before {
        background-color: #00CE5A;
    }

    #type-navigation .type-nav-btn#sources::before {
        background-color: #50DDDD;
    }

    #type-navigation .type-nav-btn#distribution::before {
        background-color: #00006E;
    }

    #type-navigation .type-nav-btn#income::before {
        background-color: #FF9BD8;
    }

    #type-navigation .type-nav-btn#contacts::before {
        background-color: #FED200;
    }

    #type-navigation .type-nav-btn#contacts:hover {
        color: #000000;
    }

.header-logo {
    width: 45.27px;
    height: 50px;
    top: 15px;
    left: 30px;
    gap: 0px;
}

.alcs-panel {
    background-color: var(--alcsWhite);
    padding: 10px;
}

    .alcs-panel .header {
        border-top: 8px solid var(--alcsSage);
        margin-bottom: 15px;
    }

        .alcs-panel .header .title {
            display: inline-flex;
            background-color: var(--alcsSage);
            color: white;
            font-size: 14px;
            font-weight: 700;
            padding: 6px 15px 10px 15px;
            line-height: 1;
        }

#alcs-content-area {
    background-color: var(--alcsGrey);
    padding: 30px;
}

.alcs-inner-panel {
    display: flex;
    flex-direction: column;
}

    .alcs-inner-panel.medium-grey {
        background-color: var(--alcsMediumGrey);
    }

    .alcs-inner-panel .header {
        border-top: 8px solid var(--alcsSage);
        margin-bottom: 15px;
    }

        .alcs-inner-panel .header .title {
            display: inline-flex;
            background-color: var(--alcsSage);
            color: white;
            font-size: 14px;
            font-weight: 700;
            padding: 6px 15px 10px 15px;
            line-height: 1;
        }

    .alcs-inner-panel .content {
        padding: 0px 15px 15px 15px;
    }

.results-list {
    list-style: none;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
}

    .results-list i {
        color: var(--alcsDarkGrey);
    }

    .results-list li {
        padding: 15px 0px;
        border-bottom: 1px dashed var(--alcsBlack);
    }

        .results-list li:last-of-type {
            border-bottom: none;
        }

        .results-list li .title-link {
            font-weight: 700;
            cursor: pointer;
        }

            .results-list li .title-link > .title-no-underline {
                text-decoration: none !important;
                color: inherit !important;
            }

            .results-list li .title-link::after {
                content: "054";
                font-family: "FontAwesome";
                margin-left: 5px;
                transition: margin-left 200ms ease;
            }

            .results-list li .title-link:hover {
                color: var(--alcsSage);
            }

                .results-list li .title-link:hover::after {
                    margin-left: 15px;
                    transition: margin-left 200ms ease;
                }

        .results-list li .info {
            font-size: 12px;
        }

            .results-list li .info .label {
                margin-right: 3px;
            }

            .results-list li .info a {
                color: var(--alcsSage);
            }

.alcs-inner-panel .footer {
    padding: 15px;
}

